* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT-Sans', sans-serif;
}

:root {
  --background: rgba(255, 255, 255, 0.9);
  --backgroundSolid: rgba(255, 255, 255, 1);
  --border: rgba(204, 204, 204, 1);
  --orange: rgba(244,122,38,1);
  --maintext: rgb(41,45,47);
  --lighttext: #888;
  --borderTrans: rgba(204, 204, 204, 0.5);
  --primary: #c2185b;
  --accent: #B68C0E;
}