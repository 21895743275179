.client__screen {
  max-width: 1200px;
  margin: 20px auto;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
}

.newClient__mainContainer {
  width: 30%;
  justify-content: flex-start;
}

.offer__btns {
  min-width: 50px;
  text-align: center;
  margin: 1px 3px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #ccc;
  font-size: 12px;
}

.client__totalMembers {
  padding-top: 10px;
  color: #ccc;
}
.client__searchResultsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 80%;
  margin: 0 auto;
  border: 1px solid #ccc;
}

.client__searchResultItem {
  /* padding: 0.5rem 2rem; */
  /* width: 80px; */
  color: #333;
  text-align: left;
  cursor: pointer;
  display: flex;
}
.client__searchResultsContainer:nth-child(odd) {
  background-color: bisque;
}
.client__searchResultsContainer:nth-child(even) {
  background-color: #ddd;
}

.client__count {
  padding-top: 10px;
  color: #ccc;
  padding-right: 10px;
}

.icon {
  width: 25px;
  height: 25px;
}
.name {
  width: 100px;
  margin-left: 10px;
}
.email {
  width: 250px;
  margin-top: 10px;
}
.signUp {
  margin-top: 10px;
}
.mailinglist {
  width: 80px;
  margin-top: 10px;
}

.client__count__control {
  margin-top: 25px;
}

.client__newClientContainer {
  border: 1px solid #1c2237;
  margin-bottom: 20px;
}
.client__header {
  height: 50px;
  background-color: #1c2237;
  width: 100%;
  font-size: 1.2rem;
  color: white;
  padding-left: 20px;
  padding-top: 12px;
}
.client__listContainer {
}
.client__listItem {
  background-color: #ddd;
  padding: 8px;
}
.client__titleContainer {
  display: flex;
  justify-content: space-between;
}
.client__name {
  width: 100%;
}

.client__selected {
  height: 20px;
  width: 20px;
  background-color: var(--accent);
  border: 1px solid #ccc;
  color: #ccc;
  padding: 1px 0 0 3px;
}
.client__nonselected {
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #ccc;
  color: white;
}

.client__inputStyle {
  font-size: 0.9em;
  width: 90%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px 0 10px 10px;
  border-radius: 4px;
  font-family: "ff-cocon-pro", "sans-serif";
  font-weight: 100;
  height: 34px;
}
.client__body {
  background-color: #ccc;
  padding-left: 20px;
  padding-bottom: 30px;
  height: 86%;
}
.client__label {
  color: #888;
  font-size: 0.8em;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}
.client__numbers {
  margin-left: 5px;
  margin-top: 10px;
}
.client__totalNumber {
  font-size: 4rem;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 960px) {
  .client__screen {
    display: flex;
  }
  .client__header {
    font-size: 1.6rem;
    padding-top: 8px;
  }
  .client__newClientContainer {
    width: 300px;
    margin-right: 20px;
  }
}
