.toggleButtonContainer {
    position: relative;
    width: 100px;
    height: 50px;
    border: 3px double #ccc;
    border-radius: 5px;
}
.toggleButtonInner {
    position: absolute;
    left: 0;
    right: unset;
    width: 48px;
    height: 44px;
    background-color: #cc6060;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
    transition: all, 0.25s ease-in-out;
}
.toggleButtonInner.selected {
    left: 48px;
    background-color:  #90bb90;
}
.toggleButtonOffText {
    font-size: 16px;
    position: absolute;
    left: 15px;
    top: 12px
}

.toggleButtonOnText {
    font-size: 16px;
    position: absolute;
    top: 12px;
    left: 60px;
}

.inputlabel {
    color: #888;
    font-size: 0.8em;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

