.editoffer__container {
  max-width: 1200px;
  margin: 0 auto;
}
.editoffer__preview {
  width: 100%;
  max-width: 400px;
  margin: 25px auto;
}
.editoffer__formContainer {
  width: 100%;
  max-width: 400px;
  margin: 25px auto;
  padding: 25px;
  border: 1px solid var(--accent);
}
.editoffer__header {
  display: flex;
  justify-content: space-around;
  margin: 25px 0;
}
.editoffer__buttons {
  width: 30%;
}
.spacer {
  border-bottom: 1px solid #ccc;
  height: 1px;
  width: 90%;
  margin: 20px auto;
}
.editoffer__mobile_preview {
  height: 667px;
  width: 375px;
  border-radius: 10px;
  border-left: 7px solid black;
  border-top: 40px solid black;
  border-bottom: 20px solid black;
  border-right: 7px solid black;
  overflow: hidden;
  position: relative;
}
.editoffers__directoryListingContainer {
  height: 200px;
  width: 375px;
  border-radius: 10px;
  border-left: 7px solid black;
  border-top: 40px solid black;
  border-bottom: 20px solid black;
  border-right: 7px solid black;
  overflow: hidden;
}
.editoffer {
  overflow: auto;
}
.editoffer__logoContainer {
  display: flex;
  justify-content: center;
  height: 150px;
  overflow: hidden;
}
.editoffer_hero_image {
  object-fit: cover;
  width: 100%;
  height: 150px;
}
.editoffer_mainContainer {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}
.editoffer_titleContent {
  margin-bottom: 10px;
}
.editoffer__town {
  background-color: #ddd;
  padding: 6px 7px 0 7px;
  border-radius: 10px;
  font-size: 0.8rem;
}
.editoffer__titleWrapper {
  display: flex;
  justify-content: space-between;
}
.editoffer__description {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 0.8rem;
  overflow-wrap: break-word;
  text-align: justify;
}
.editoffers__bottomImageContainer {
  display: flex;
  justify-content: stretch;
  min-height: 150px;
}
.editoffers__bottomImage {
  width: 50%;
}
img.bottomImage {
  max-width: 150px;
  object-fit: cover;
  height: 150px;
  width: 100%;
}
.editoffer_upload_btn_wrapper {
  display: flex;
  flex-direction: column;
}
.editoffer__imageLabel {
  font-size: 0.7em;
  color: #888;
  margin-bottom: 10px;
}
.editoffers__addressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: space-between;*/
  border-top: 5px solid var(--accent);
  border-bottom: 5px solid var(--accent);
  padding: 10px 0;
}

.editoffers__title {
  color: red;
  margin-bottom: 10px;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-size: 0.9em;
  font-family: ff-cocon-pro sans-serif;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 3px 0 0 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

    */
  /*background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),*/
  /*linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);*/
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 1px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
  font-size: 0.9em;
  font-family: ff-cocon-pro, sans-serif;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  /*background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),*/
  /*linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);*/
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}

.datePicker {
  font-size: 0.9em;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 7px 10px 7px 10px;
  border-radius: 4px;
  font-family: "ff-cocon-pro", "sans-serif";
  font-weight: 100;
}
.react-date-picker__inputGroup__input {
  font-family: "ff-cocon-pro", "sans-serif" !important;
  font-weight: 100;
}
.react-date-picker__wrapper {
  border: none !important;
}
.innerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 3px solid var(--accent);
  border-radius: 10px;
  overflow: hidden;
  height: 110px;
}
.imageContainer {
  width: 30%;
  border-radius: 50%;
  overflow: hidden;
}
.editoffer_logo_image {
  object-fit: cover;
  width: 150px;
  height: 150px;
}
.details {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.name {
  height: 30px;
  font-size: 15px;
  color: black;
  margin-top: 10px;
}
.date {
  height: 20px;
  font-size: 12px;
}
.offer {
  height: 20px;
  font-size: 12px;
}

.backgroundImageContainer {
  width: 100%;
  height: 100%;
  background-color: lightgray;
}
.backgroundImage {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.previewLogoContainer {
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previewLogo {
  width: 100px;
}

.previewSpacer {
  border-bottom: 1px solid #fff;
  height: 1px;
  width: 90%;
  margin: 20px auto;
}

.previewDescriptionContainer {
  height: 60%;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  padding: 15px 15px 30px 15px;
  overflow-y: auto;
}
.nameTextContainer {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: Bold;
}
.titleTextContainer {
  font-size: 11px;
  font-weight: 400;
}
.offerTitleContainer {
  margin-bottom: 20px;
}
.discountButtonContainer {
  border-color: #b68c0e;
  border-width: 3px;
  border-style: solid;
  border-radius: 20px;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  background-color: #fff;
  cursor: pointer;
}
.discountButtonText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.discountModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto auto;
  width: 80%;
  height: 60%;
  background-color: #fff;
  border-radius: 20px;
  background-image: url("./../../assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.discountVoucherTitleContainer {
  width: 40%;
  margin: 20px auto;
  text-align: center;
}
.discountVoucherTitle {
  background-color: rgba(255, 255, 255, 0.5);
}
.voucherImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.voucherImage {
  background-image: url("../../assets/images/Voucher.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
  height: 90px;
  color: white;
}
.voucherInfoContainer {
  color: white;
  font-size: 10px;
  margin: 0 auto 20px auto;
}
.codeContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.expiryDateContainer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
}
.clipboardText {
  font-size: 12px;
}

@media screen and (min-width: 960px) {
  .editoffer__wrapper {
    display: flex;
    justify-content: space-around;
  }
  .editoffer__formContainer {
    margin-left: 10px;
  }
  .editoffers__directoryListingContainer,
  .editoffer__preview {
    padding-left: 12px;
  }
}
