.editoffer__magazineContainer {
  width: 100%;
  max-width: 800px;
  margin: 25px 20px;
  padding: 25px;
  border: 1px solid var(--accent);
  gap: 1rem;
}

.tableCell {
  width: 25%;
  text-align: center;
}

.tableRow {
  border-bottom: 1px solid grey;
}
.button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 {
  background-color: #4caf50;
} /* Green */

#issue {
  font-size: 0.9em;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px 0 10px 10px;
  border-radius: 4px;
  font-family: "ff-cocon-pro", "sans-serif";
  font-weight: 100;
}
