:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: var(--primary);
    color: #888;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 100;
    margin: 4px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}
.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}
.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 64px;
    font-size: 20px;
}
.btn--full {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
}
.btn--large:hover, .btn--medium:hover, .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #242424;
}
.btn--full:hover,
.btn--wide:hover {
    color: #fff;
    background-color: #f00946;
    transition: all 0.2s ease-out;
}
.blue {
    background-color: #276afb;
    color: #fff;
    border: none;
}
.red {
    background-color: #f00946;
    color: #fff;
    border: none;
}
.primary {
    background-color: #242424;
    color: #fff;
    border: none;
}
.primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
}
.green {
    background-color: #25ce4a;
    color: #fff;
    border: none;
}
.red:hover,
.green:hover {
    background-color: #242424;
}

.accent {
    background-color: #B68C0E;
    color: #fff;
    border: none;
}
.accent:hover {
    background-color: #c2185b;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}