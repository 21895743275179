.wrapper {
    width: 80%;
    max-width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginContainer {

    padding: 40px 0px 20px 0px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0 0 0 / 16%);
    margin-bottom: 20px;
}
.logoContainer {
    display: flex;
    justify-content: center;
}

.logo {
    width: 90%;

    margin-bottom: 30px;
}

.loginForm {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.error {
    background-color: #f00946;
    color: white;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
}